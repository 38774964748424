/**
 * Created by t.leckeband on 30.08.22.
 */
define('services/CustomerPriceService',['app', 'modules/module', 'services/EntityCrudService', 'services/NotificationService'], function (app, module) {
	'use strict';
	return module.factory('CustomerPriceService', [
		'$injector',
		'$q',
		'NotificationService',
		'ModelFactory',
		CustomerPriceService,
	]);
});

function CustomerPriceService($injector, $q, NotificationService, ModelFactory) {
	crudInstance = $injector.instantiate(CrudService);
	crudInstance.setType('CustomerPrice');

	angular.extend(this, crudInstance);
	let _customerPrices = [];

	this.findAll = function ({ customerId }) {
		return $injector
			.get('ModelFactory')
			.CustomerPrice()
			.findAll({ customerId })
			.$promise.then((response) => {
				if(response.data) {
					_customerPrices = response.data;
				}
			});
	};

	this.getAll = () => {
		return _customerPrices;
	};

	return this;
}
;
